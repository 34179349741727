import React, { useEffect, useState } from "react"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from '../../assets/scss/admin/index.module.scss'
import firebase from '../../utils/firebase'
import CircularProgress from "@material-ui/core/CircularProgress"
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import profile from '../common/Profile';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getUserType} from '../../utils/userTypes'

export default (props) => {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const fetchUsers = () => {
    const functions = firebase.functions();
    functions.httpsCallable("getUsers")().then(res => {
      setUsers(res.data)
    }).catch(e => {
      console.log(e);
    })
  }

  // componentDidMount
  useEffect(() => {
    fetchUsers()
  }, []);

  const handleEditClick = (u) => {
    setEditUser(u)
  }

  const handleUpdated = (e) => {
    setEditUser(false)
    setUsers([])
    fetchUsers()
    setDialogMessage('更新しました。')
  }
  const Profile = profile('admin')

  return (
    <React.Fragment>
      <Dialog
        open={!!editUser}
        maxWidth="md"
        fullWidth={true}
        onClose={(e) => { setEditUser(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">会員情報変更</DialogTitle>
        <DialogContent>
          <Profile
            user={ editUser }
            handleUpdated={ handleUpdated }
          />
        </DialogContent>
      </Dialog>
      <Dialog open={ dialogMessage !== '' }>
        <DialogContent>
          { dialogMessage }
        </DialogContent>
        <DialogActions>
          <Button onClick={ (e) => { setDialogMessage('') } } color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="users">
          <TableHead>
            <TableRow>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { ( () => {
              if( users.length === 0 ){
                return <TableRow className={styles.root} >
                  <TableCell align="center" colSpan={5} style={ {padding: '20px'} }>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              } else
                return users.map((user, index) => {
                  return <TableRow className={styles.root} key={index}>
                    <TableCell align="left">{ user.email }</TableCell>
                    <TableCell align="left">{ user.phone }</TableCell>
                    <TableCell align="left">{ getUserType(user.userType).value }</TableCell>
                    <TableCell align="left">{ user.prefecture } { user.city }</TableCell>
                    <TableCell align="left">
                      <IconButton aria-label="Edit" onClick={(e) => { let u = user; handleEditClick(u) }}>
                        <EditIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                })
            } )() }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}