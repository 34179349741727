const UserTypelist = [
  { id: 1, value: 'フリーメンバー' },
  { id: 2, value: '有料メンバー' },
  { id: 3, value: 'コアメンバー' },
]

export function getUserType (userTypeId) {
  return UserTypelist.find(function(userType){
    return userTypeId === userType.id
  })
}

export default UserTypelist