import React from "react"
import Layout from "../../components/admin/Layout"
import Users from "../../components/admin/Users"

const Index = props => {
  return (
    <div>
      <Layout>
        <Users />
      </Layout>
    </div>
  )
}

export default Index
