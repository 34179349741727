import React, { useEffect, useState, cloneElement } from "react"
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircle from '@material-ui/icons/AccountCircle';
import firebase from '../../utils/firebase'
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import CalendarIcon from "@material-ui/icons/CalendarToday"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  loading: {
    marginTop: '100px',
    textAlign: 'center',
    width: '100%',
  },
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#AAAAAA',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  rightBar: {
    marginLeft: 0,
  }
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading] = useState(false);
  const [open, setOpen] = useState(false);
  const [adminUser, setAdminUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { children } = props
  const menuId = 'profile-menu';
  const isMenuOpen = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    firebase.auth().signOut();
  }

  const handleProfileMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
    </Menu>
  );


  const loginRequired = async () => {
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 3600000,
    };
    remoteConfig.defaultConfig = ({
      'adminUsers': [],
    });
    await remoteConfig.fetchAndActivate()
    const configValue = remoteConfig.getValue('adminUsers')
    const validAdminUsers = JSON.parse(configValue._value)

    firebase.auth().onAuthStateChanged(function(u) {
      if(u){
        var gUser = u.providerData.find((prov) => {return prov.providerId === 'google.com'})
        if(gUser){
          if(validAdminUsers.indexOf(gUser.email) > -1) {
            const { uid, email } = gUser
            setAdminUser( { uid, email } )
            return
          } else {
            firebase.auth().signOut();
          }
        }
      }
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/plus.login');
      firebase.auth().signInWithRedirect(provider);
      return
    })
  }

  // componentDidMount
  useEffect(() => {
    loginRequired()
  }, []);

  return (
    ( () => {
      if( !adminUser ){
        return <div className={classes.loading}>
          <CircularProgress />
        </div>
      } else {
        return <div className={classes.root}>
          <Dialog open={loading}>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Dialog>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                古民家箕澤屋を守る会　管理画面
              </Typography>
              <div className={classes.grow} />
              <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                  aria-label="account of current adminUser"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </MenuItem>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem button key="home" component="a" href="/admin" >
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="会員管理" />
              </ListItem>
              <ListItem button key="calendar" component="a" href="/admin/calendar">
                <ListItemIcon><CalendarIcon /></ListItemIcon>
                <ListItemText primary="カレンダー" />
              </ListItem>
              <ListItem button key="reservation" component="a" href="/admin/reservations">
                <ListItemIcon><CalendarIcon /></ListItemIcon>
                <ListItemText primary="施設予約" />
              </ListItem>
              <ListItem button key="drive" component="a" target="_blank" href="https://drive.google.com/drive/folders/1zjOXPyP83n97GqvOI3PTiIxS36GEyJ5p?usp=sharing">
                <ListItemIcon><CalendarIcon /></ListItemIcon>
                <ListItemText primary="ドライブ" />
              </ListItem>
            </List>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            {cloneElement(children, { adminUser: adminUser })}
          </main>
          {renderProfileMenu}
        </div>
      }
    })()
  );
}
