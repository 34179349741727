import { Link } from "gatsby"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import firebase from "../../utils/firebase"
import React, { useEffect, useState } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import styles from "../../assets/scss/member/profile.module.scss"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import JapanPref from "../../utils/japanPref"
import UserTypes from "../../utils/userTypes"

// screeen: admin | profile | signup
export default screen => {
  return props => {
    // userがあればProfile更新、なければ新規会員登録
    var currentUser = props.user

    const [loading, setLoading] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")

    const [email, setEmail] = useState(currentUser ? currentUser.email : "")
    const [errorEmail, setErrorEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorPassword, setErrorPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [errorPasswordConfirm, setErrorPasswordConfirm] = useState("")

    const [name, setName] = useState(
      currentUser && currentUser.name ? currentUser.name : ""
    )
    const [errorName, setErrorName] = useState("")

    const [postalCode, setPostalCode] = useState(
      currentUser && currentUser.postalCode ? currentUser.postalCode : ""
    )
    const [errorPostalCode, setErrorPostalCode] = useState("")
    const [prefecture, setPrefecture] = useState(
      currentUser && currentUser.prefecture ? currentUser.prefecture : ""
    )
    const [errorPrefecture, setErrorPrefecture] = useState("")
    const [city, setCity] = useState(currentUser ? currentUser.city : "")
    const [errorCity, setErrorCity] = useState("")
    const [address1, setAddress1] = useState(
      currentUser && currentUser.address1 ? currentUser.address1 : ""
    )
    const [errorAddress1, setErrorAddress1] = useState("")
    const [address2, setAddress2] = useState(
      currentUser && currentUser.address2 ? currentUser.address2 : ""
    )
    const [errorAddress2] = useState("")
    const [phone, setPhone] = useState(
      currentUser && currentUser.phone ? currentUser.phone : ""
    )
    const [errorPhone, setErrorPhone] = useState("")
    const [userType, setUserType] = useState(
      currentUser && currentUser.userType ? currentUser.userType : ""
    )
    const [errorUserType, setErrorUserType] = useState("")

    // componentDidMount
    useEffect(() => {}, [])

    const validateEmail = e => {
      if (email === "") {
        setErrorEmail("入力してください。")
        return false
      } else {
        if (email.length >= 100) {
          setErrorEmail("長すぎます。100文字まで")
          return false
        }
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i
        if (email.match(re) === null) {
          setErrorEmail("メールフォーマットが違います。")
          return false
        }
      }
      setErrorEmail("")
      return true
    }

    const validatePassword = e => {
      if (password === "") {
        setErrorPassword("入力してください。")
        return false
      } else {
        if (password.length >= 100) {
          setErrorPassword("長すぎます。100文字まで")
          return false
        }
        if (password.length < 6) {
          setErrorPassword("短すぎます。6文字以上")
          return false
        }
      }
      setErrorPassword("")
      return true
    }

    const validatePasswordConfirm = e => {
      if (passwordConfirm === "") {
        setErrorPasswordConfirm("入力してください。")
        return false
      } else {
        if (password !== passwordConfirm) {
          setErrorPasswordConfirm("パスワードがマッチしません")
          return false
        }
      }
      setErrorPasswordConfirm("")
      return true
    }

    const validateName = e => {
      if (name === "") {
        setErrorName("入力してください。")
        return false
      }
      setErrorName("")
      return true
    }

    const validatePostalCode = e => {
      if (postalCode === "") {
        setErrorPostalCode("入力してください。")
        return false
      } else {
        let postalCodeWithoutDash = postalCode.replace("-", "")
        var re = /^[0-9]{7}$/i
        if (postalCodeWithoutDash.match(re) === null) {
          setErrorPostalCode("郵便番号が違います")
          return false
        }
      }
      setErrorPostalCode("")
      return true
    }

    const validatePrefecture = e => {
      if (prefecture === "") {
        setErrorPrefecture("入力してください。")
        return false
      }
      setErrorPrefecture("")
      return true
    }

    const validateUserType = e => {
      if (userType === "") {
        setErrorUserType("入力してください。")
        return false
      }
      setErrorUserType("")
      return true
    }

    const validateCity = e => {
      if (city === "") {
        setErrorCity("入力してください。")
        return false
      }
      setErrorCity("")
      return true
    }

    const validateAddress1 = e => {
      if (address1 === "") {
        setErrorAddress1("入力してください。")
        return false
      }
      setErrorAddress1("")
      return true
    }

    const validatePhone = e => {
      if (phone === "") {
        setErrorPhone("入力してください。")
        return false
      }
      setErrorPhone("")
      return true
    }

    const handlePostalCodeChange = e => {
      var re = /^[0-9-]{0,}$/
      if (e.target.value.match(re) === null) return
      setPostalCode(e.target.value)
    }

    const handleAddressSearch = e => {
      setLoading(true)
      if (!validatePostalCode()) return
      let postalCodeWithoutDash = postalCode.replace("-", "")
      var db = firebase.firestore()
      db.collection("postalCodes")
        .doc(postalCodeWithoutDash)
        .get()
        .then(doc => {
          if (doc.exists) {
            let postal = doc.data()
            setPrefecture(postal.prefecture)
            setCity(postal.city)
            setAddress1(postal.address1)
          }
          setLoading(false)
        })
    }

    const handleCreated = e => {
      setDialogMessage("")
      window.location.replace("/member")
    }

    const validCreateAll = () => {
      let resValidateEmail = validateEmail()
      let resValidatePassword = validatePassword()
      let resValidatePasswordConfirm = validatePasswordConfirm()
      let resValidateName = validateName()

      return (
        resValidateEmail &&
        resValidatePassword &&
        resValidatePasswordConfirm &&
        resValidateName
      )
    }

    const validUpdateAll = () => {
      let resValidateName = validateName()

      return resValidateName
    }

    const handleAdminUpdate = e => {
      e.preventDefault()
      setLoading(true)
      const functions = firebase.functions()
      functions
        .httpsCallable("setUser")({
          id: currentUser.id,
          name: name,
          postalCode: postalCode,
          prefecture: prefecture,
          city: city,
          address1: address1,
          address2: address2,
          phone: phone,
          updatedAt: new Date(),
          userType: userType,
        })
        .then(res => {
          props.handleUpdated()
          setLoading(false)
        })
        .catch(e => {
          console.log(e)
        })
    }

    const handleUpdate = e => {
      e.preventDefault()
      if (!validUpdateAll()) return

      setLoading(true)
      ;(async (arg_email, arg_password) => {
        var db = firebase.firestore()
        var usersRef = db.collection("users")
        usersRef.doc(currentUser.uid).update({
          name: name,
          updatedAt: new Date(),
        })
        setDialogMessage("更新しました。")
        setLoading(false)
      })(email, password)
    }

    const handleSignUp = e => {
      e.preventDefault()
      if (!validCreateAll()) return

      setLoading(true)
      ;(async (arg_email, arg_password) => {
        let res = await firebase
          .auth()
          .createUserWithEmailAndPassword(arg_email, arg_password)
          .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message
            // [START_EXCLUDE]
            if (errorCode === "auth/weak-password") {
              setErrorPassword("The password is too weak.")
            } else {
              setErrorEmail(errorMessage)
            }
            setLoading(false)
            // [END_EXCLUDE]
          })

        if (!res || !res.user) return

        var db = firebase.firestore()
        var usersRef = db.collection("users")
        usersRef.doc(res.user.uid).set({
          email: email,
          name: name,
          userType: 1,
          updatedAt: new Date(),
          createdAt: new Date(),
        })
        const userDoc = await usersRef.doc(res.user.uid).get()
        const user = userDoc.data()
        const functions = firebase.functions()
        functions.useFunctionsEmulator("http://localhost:5001")
        functions
          .httpsCallable("sendSignupMessage")({
            user: user,
          })
          .then(resFunction => {
            setLoading(false)
            setDialogMessage(
              "メールアドレスにメールを送信しました。ご確認ください。"
            )
          })
          .catch(e => {
            console.log(e)
          })
      })(email, password)
    }

    const handleSubmit = e => {
      if (screen === "admin") {
        handleAdminUpdate(e)
      } else if (currentUser) {
        handleUpdate(e)
      } else {
        handleSignUp(e)
      }
    }

    return (
      <div className={styles.root}>
        <Dialog open={loading}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <Dialog open={dialogMessage !== ""}>
          <DialogContent>{dialogMessage}</DialogContent>
          <DialogActions>
            <Button onClick={handleCreated} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Paper>
          <div className={styles.elemColumn}>
            {currentUser ? (
              <Typography variant="h5">アカウント情報</Typography>
            ) : (
              <>
                <Typography component="h1" variant="h5">
                  MISAWAYA CLOUD
                </Typography>
                <Typography>新規メンバー登録 Signup</Typography>
              </>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div key={4} className={styles.elem}>
              <TextField
                label="Your Name"
                fullWidth
                variant="outlined"
                value={name}
                error={errorName !== ""}
                helperText={errorName}
                onBlur={validateName}
                onChange={e => {
                  setName(e.target.value)
                }}
                autoComplete="off"
              />
            </div>
            {(() => {
              if (!currentUser) {
                return [
                  <div key={1} className={styles.elem}>
                    <TextField
                      label="Email"
                      fullWidth
                      variant="outlined"
                      value={email}
                      error={errorEmail !== ""}
                      helperText={errorEmail}
                      onBlur={validateEmail}
                      onChange={e => {
                        setEmail(e.target.value)
                      }}
                      autoComplete="off"
                    />
                  </div>,
                  <div key={2} className={styles.elem}>
                    <TextField
                      label="Password"
                      fullWidth
                      variant="outlined"
                      type="password"
                      value={password}
                      error={errorPassword !== ""}
                      helperText={errorPassword}
                      onBlur={validatePassword}
                      onChange={e => {
                        setPassword(e.target.value)
                      }}
                      autoComplete="off"
                    />
                  </div>,
                  <div key={3} className={styles.elem}>
                    <TextField
                      label="Password Confirm"
                      fullWidth
                      variant="outlined"
                      type="password"
                      value={passwordConfirm}
                      error={errorPasswordConfirm !== ""}
                      helperText={errorPasswordConfirm}
                      onBlur={validatePasswordConfirm}
                      onChange={e => {
                        setPasswordConfirm(e.target.value)
                      }}
                      autoComplete="off"
                    />
                  </div>,
                ]
              }
            })()}

            {(() => {
              if (screen === "admin") {
                return (
                  <React.Fragment>
                    <div className={styles.elem}>
                      <div>
                        <TextField
                          id="postal_code"
                          label="Postal Code"
                          helperText={errorPostalCode}
                          error={errorPostalCode !== ""}
                          value={postalCode}
                          onBlur={validatePostalCode}
                          onChange={handlePostalCodeChange}
                          margin="normal"
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <Button color="primary" onClick={handleAddressSearch}>
                          住所検索
                        </Button>
                      </div>
                    </div>
                    <div className={`${styles.elem} + ${styles.pref}`}>
                      <FormControl
                        className={styles.formControl}
                        error={errorPrefecture !== ""}
                      >
                        <InputLabel id="prefecture-label">
                          Prefecture
                        </InputLabel>
                        <Select
                          value={prefecture}
                          onChange={e => {
                            setPrefecture(e.target.value)
                          }}
                          inputProps={{
                            name: "prefecture",
                            id: "prefecture",
                          }}
                          onBlur={validatePrefecture}
                        >
                          {(() => {
                            return JapanPref.map((name, i) => {
                              return (
                                <MenuItem key={i} value={name}>
                                  {name}
                                </MenuItem>
                              )
                            })
                          })()}
                        </Select>
                        {(() => {
                          if (errorPrefecture !== "") {
                            return (
                              <FormHelperText>{errorPrefecture}</FormHelperText>
                            )
                          }
                        })()}
                      </FormControl>
                    </div>
                    <div className={styles.elem}>
                      <TextField
                        label="City"
                        value={city}
                        error={errorCity !== ""}
                        helperText={errorCity}
                        onBlur={validateCity}
                        onChange={e => {
                          setCity(e.target.value)
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className={styles.elem}>
                      <TextField
                        label="Address1"
                        value={address1}
                        error={errorAddress1 !== ""}
                        helperText={errorAddress1}
                        onBlur={validateAddress1}
                        onChange={e => {
                          setAddress1(e.target.value)
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className={styles.elem}>
                      <TextField
                        label="Address2"
                        value={address2}
                        error={errorAddress2 !== ""}
                        helperText={errorAddress2}
                        onChange={e => {
                          setAddress2(e.target.value)
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className={styles.elem}>
                      <TextField
                        label="Phone"
                        value={phone}
                        error={errorPhone !== ""}
                        helperText={errorPhone}
                        onChange={e => {
                          setPhone(e.target.value)
                        }}
                        onBlur={validatePhone}
                        autoComplete="off"
                      />
                    </div>

                    <div className={styles.elem}>
                      <FormControl
                        className={styles.formControl}
                        error={errorUserType !== ""}
                      >
                        <InputLabel id="prefecture-label">User Type</InputLabel>
                        <Select
                          value={userType}
                          onChange={e => {
                            setUserType(e.target.value)
                          }}
                          inputProps={{
                            name: "userType",
                            id: "userType",
                          }}
                          onBlur={validateUserType}
                        >
                          {(() => {
                            return UserTypes.map((ut, i) => {
                              return (
                                <MenuItem key={i} value={ut.id}>
                                  {ut.value}
                                </MenuItem>
                              )
                            })
                          })()}
                        </Select>
                      </FormControl>
                    </div>
                  </React.Fragment>
                )
              }
            })()}
            <div className={styles.submit}>
              {(() => {
                if (currentUser) {
                  return (
                    <Button type="submit" color="primary" variant="contained">
                      更新 Update
                    </Button>
                  )
                } else {
                  return (
                    <>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        登録する Signup
                      </Button>
                      <Typography>
                        <Link to="/member/login" className={styles.login_link}>
                          ログイン
                        </Link>
                      </Typography>
                    </>
                  )
                }
              })()}
            </div>
          </form>
        </Paper>
      </div>
    )
  }
}
